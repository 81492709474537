<template>
	<f-card>
		<v-data-table
			:headers="headers"
			:items="paymentMethods"
			@click:row="edit" />
	</f-card>
</template>

<script>
export default {
	data() {
		return {
			headers: [
				{
					text: this.$t("pages.settings.payment.list.description"),
					align: "start",
					sortable: true,
					value: "description",
				},
				{
					text: this.$t("pages.settings.payment.list.createdAt"),
					value: "created_at",
					align: "end",
				},
			],
		};
	},
	computed: {
		paymentMethods() {
			return this.$store.state.paymentMethods.list;
		},
		loading() {
			return !this.$store.state.system.loading.done;
		},
	},
	methods: {
		edit(paymentMethod) {
			this.$router.push({
				name: "settings-payment-methods-edit",
				params: {
					id: paymentMethod.id,
				},
			});
		},
	},
};
</script>
